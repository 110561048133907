/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/foundation-sites@6.7.4/dist/js/plugins/foundation.core.min.js
 * - /npm/foundation-sites@6.7.4/dist/js/plugins/foundation.util.keyboard.min.js
 * - /npm/foundation-sites@6.7.4/dist/js/plugins/foundation.util.mediaQuery.min.js
 * - /npm/foundation-sites@6.7.4/dist/js/plugins/foundation.util.triggers.min.js
 * - /npm/foundation-sites@6.7.4/dist/js/plugins/foundation.accordion.min.js
 * - /npm/foundation-sites@6.7.4/dist/js/plugins/foundation.offcanvas.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
